import React from 'react';

import { FCWithChildren } from '@wix/challenges-web-library';
import { ParticipantStep } from '../../types/v3Types';

export interface IIsStepHiddenContext {
  isStepHidden(step: ParticipantStep): boolean;
  isVisibleStepLockedForComplete(step: ParticipantStep): boolean;
}

export const IsStepHiddenContext = React.createContext<IIsStepHiddenContext>({
  isStepHidden: null,
  isVisibleStepLockedForComplete: null,
});

export const IsStepHiddenConsumer = IsStepHiddenContext.Consumer;

export const useIsStepHidden = () => React.useContext(IsStepHiddenContext);

export function withIsStepHidden<T>(Component: any): FCWithChildren<T> {
  return (props) => {
    return (
      <IsStepHiddenConsumer>
        {(value) => {
          return <Component {...(props as T)} {...value} />;
        }}
      </IsStepHiddenConsumer>
    );
  };
}
