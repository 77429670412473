export interface TPARef {
  pageRef?: PageRef;
  pageUriSEO?: string;
  tpaPageId?: string;
  managingAppDefId?: string;
  title?: string;
}

export interface TPAAppRef extends TPARef {
  instanceId: string;
}

export interface TPACompRef extends TPARef {
  compId: string;
}

export interface TPAComp {
  componentType: TPAComponentType;
  appDefinitionId?: string;
  copyStyle?: boolean;
  styleId?: string;
  widget?: {
    widgetId: string;
    allPages?: boolean;
    wixPageId?: string;
  };
  page?: {
    pageId: string;
    title?: string;
    isHidden?: boolean;
  };
}

// export interface RouterDescr {
//   prefix: string;
//   config?: object;
//   group?: string;
// }

export interface PageRouter {
  routerRef: RouterRef;
  pageRef: PageRef;
  pageRoles?: string[];
}

export type PageType = 'MOBILE' | 'DESKTOP';

export interface ComponentRef {
  id: string;
  type: PageType;
  innerPath?: string[];
}

export interface Connection {
  role: string;
  controllerRef: ComponentRef;
  isPrimary?: boolean;
  config?: any;
}

export interface PageRef {
  id: string;
  type?: PageType;
}

export enum LinkTypes {
  NoLink = 'NoLink',
  PageLink = 'PageLink',
  AnchorLink = 'AnchorLink',
  ExternalLink = 'ExternalLink',
  DocumentLink = 'DocumentLink',
  PhoneLink = 'PhoneLink',
  EmailLink = 'EmailLink',
  LoginToWixLink = 'LoginToWixLink',
  DynamicPageLink = 'DynamicPageLink',
  EdgeAnchorLink = 'EdgeAnchorLinks',
  PopupLink = 'PopupLink',
  FormSubmitButtonLink = 'FormSubmitButtonLink',
}

export interface LinkData {
  type: LinkTypes;
}

export interface PageLink extends LinkData {
  pageId: string;
}

export interface DynamicPageLink extends LinkData {
  routerId: string;
  innerRoute: string;
  anchorDataId?: string;
}

export interface RouterRef {
  id: string;
}

export interface ComponentDefinition {
  componentType: string;
  data?: any;
  style?: any;
  layout?: any;
}

export type Link = object;

export interface ImageMediaResult {
  uri: string;
  title: string;
  width: number;
  height: number;
}

export interface DocumentMediaResult {
  uri: string;
  title: string;
}

export interface VideoMediaResult {
  id: string;
  title: string;
  posterUri: string;
  posterWidth: number;
  posterHeight: number;
}

export enum DeviceType {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export interface BasicMenuItem {
  id: string;
  isVisible?: boolean;
  isVisibleMobile?: boolean;
  items?: BasicMenuItem[];
  label?: string;
  link: Link;
  type: 'BasicMenuItem';
}

export interface CustomMenuData {
  id: string;
  items?: BasicMenuItem[];
  name?: string;
  appId: string;
  type: 'CustomMenu';
}

export interface RouterPage {
  pageRef: PageRef;
  pageRoles: string[];
}

export interface RouterData {
  id: string;
  prefix: string;
  config: object;
  pages: RouterPage[];
}

export interface RouterPrefixValidationObject {
  errorCode: '';
  message: '';
  valid: true;
}

export interface PageProperties {
  hidePage: boolean;
  pageSecurity?: {
    requireLogin: boolean;
    passwordDigest: string;
    dialogLanguage: string;
  };
  title: string;
}

export interface PageData {
  title: string;
  id: string;
  type: string;
  hideTitle: boolean;
  icon: string;
  windowTitle: string;
  descriptionSEO: string;
  metaKeywordsSEO: string;
  pageTitleSEO: string;
  pageUriSEO: string;
  hidePage: boolean;
  underConstruction: boolean;
  tpaApplicationId: number;
  managingAppDefId: string;
  tpaPageId: string;
  pageSecurity: object;
  indexable: boolean;
  mobileHidePage: true;
  pageBackgrounds: object;
  isLandingPage: boolean;
  isMobileLandingPage: boolean;
  isPopup: boolean;
  translationData: object;
}

export interface PopupData {
  title: string;
  hideTitle: boolean;
  icon: string;
  windowTitle: string;
  descriptionSEO: string;
  metaKeywordsSEO: string;
  pageTitleSEO: string;
  pageUriSEO: string;
  hidePage: true;
  underConstruction: boolean;
  tpaApplicationId: number;
  managingAppDefId: string;
  tpaPageId: string;
  pageSecurity: object;
  indexable: false;
  mobileHidePage: true;
  pageBackgrounds: object;
  isLandingPage: boolean;
  isMobileLandingPage: boolean;
  isPopup: true;
  translationData: object;
}

export type Font = object;

export interface FontOption {
  lang: string;
  fonts: Font[];
}

export interface Layout {
  fixedPosition: boolean;
  height: number;
  rotationInDegrees: number;
  scale: number;
  width: number;
  x: number;
  y: number;
}

export interface LayoutUpdateObject {
  fixedPosition?: boolean;
  height?: number;
  rotationInDegrees?: number;
  scale?: number;
  width?: number;
  x?: number;
  y?: number;
}

export enum TPAComponentType {
  Page = 'PAGE',
  Widget = 'WIDGET',
}

export interface ColorPalete {
  color_1: string;
  color_2: string;
  color_3: string;
  color_4: string;
  color_5: string;
  color_6: string;
  color_7: string;
  color_8: string;
  color_9: string;
  color_10: string;
  color_11: string;
  color_12: string;
  color_13: string;
  color_14: string;
  color_15: string;
  color_16: string;
  color_17: string;
  color_18: string;
  color_19: string;
  color_20: string;
  color_21: string;
  color_22: string;
  color_23: string;
  color_24: string;
  color_25: string;
  color_26: string;
  color_27: string;
  color_28: string;
  color_29: string;
  color_30: string;
  color_31: string;
  color_32: string;
  color_33: string;
  color_34: string;
  color_35: string;
}

export type PageChallengeDataPointer = object;

export interface Mode {
  label: string;
  modeId: string;
  params: string;
  type: string;
}
