import type { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ScreenNames } from '../main/biInterfaces';
import { useControllerData } from '../main/controllerContext';

export enum Pages {
  List = 'Challenges',
  Details = 'challenge_page',
  Participant = 'Participant Page',
  Payment = 'Challenge Payment',
  ThankYou = 'Thank You Page',
  ChallengesList = 'Challenges List Page',
}

export const ScreenNamesByPageId: Record<Pages, ScreenNames> = {
  [Pages.Details]: ScreenNames.ChallengePageForParticipant,
  [Pages.Participant]: ScreenNames.ChallengePageForParticipant,
  [Pages.ChallengesList]: ScreenNames.ChallengesListPage,
  [Pages.List]: ScreenNames.ChallengeListWidget,
  [Pages.Payment]: ScreenNames.PaymentPage,
  [Pages.ThankYou]: ScreenNames.ThankYouPage,
};

export const PageSlugs: Record<Pages, string> = {
  [Pages.List]: 'challenges',
  [Pages.Details]: 'challenge-page',
  [Pages.Participant]: 'participant-page',
  [Pages.Payment]: 'challenge-payment',
  [Pages.ThankYou]: 'challenge-thanks',
  [Pages.ChallengesList]: 'challenges-list',
};

export interface IGoToPageOptions {
  pageId: Pages | string;
  appDefinitionId?: string;
  challengeId?: string;
  deepLink?: string;
  reloadPage?: boolean;
  queryParams?: { [key: string]: string };
  isAbsoluteURL?: boolean;
}

export interface ILocationContext {
  location: ILocation;
  baseUrl: string;
  path: string[];
  query: {
    [key: string]: string;
  };
  goToExternalUrl(url: string): void;
  goToPage(options: IGoToPageOptions): void;
  navigateToProgramPage({
    challengeId,
    isParticipant,
    queryParams,
  }: {
    challengeId: string;
    isParticipant: boolean;
    queryParams?: any;
  }): Promise<void>;
}

export const useLocation = () => {
  const props = useControllerData();
  return {
    baseUrl: props.baseUrl,
    path: props.path,
    query: props.query,
    location: props.location,
    goToPage: props.goToPage,
    goToExternalUrl: props.goToExternalUrl,
    navigateToProgramPage: props.navigateToProgramPage,
  };
};
