import type { Participant as ParticipantV1 } from '@wix/ambassador-challenges-v1-participant/types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';

export type {
  Step as StepV3,
  QuizOptions as QuizV3,
} from '@wix/ambassador-online-programs-v3-step/types';
import type {
  Section as SectionV3,
  Description as DescriptionV3,
} from '@wix/ambassador-online-programs-v3-section/types';
import type { ObjectDescription } from '@wix/ambassador-challenges-v1-challenge/types';
import type { Participant as ParticipantV3 } from '@wix/ambassador-online-programs-v3-participant/types';

export type { ResolvedStep } from '@wix/ambassador-online-programs-v3-resolved-step/types';
export { Status } from '@wix/ambassador-online-programs-v3-resolved-step/types';
export type { Participant as ParticipantV3 } from '@wix/ambassador-online-programs-v3-participant/types';
export type { Participant as ParticipantV1 } from '@wix/ambassador-challenges-v1-participant/types';
export type Step = StepV3;

export type StepV3WithDelay = StepV3 & { delay: number };

export type StepWithDelay = StepV3WithDelay;

export type StepTransition = {
  state?: `${ParticipantStepState}`;
  occurredAt?: Date | string | null;
};

export type Section = SectionV3;
export type Description = DescriptionV3 | ObjectDescription;
export type SectionV3WithSteps = SectionV3 & { steps: StepV3WithDelay[] } & {
  transitions?: StepTransition[];
};

export type SectionWithSteps = SectionV3WithSteps;

export type DateFrame = {
  start?: string;
  finish?: string | null;
};

export type Participant = ParticipantV1 | ParticipantV3;

export enum ParticipantStepState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  SKIPPED = 'SKIPPED',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  OVERDUE = 'OVERDUE',
  FAILED = 'FAILED',
}

export interface QuizSubmission {
  quizSubmissionId?: string;
  score?: number | null;
  grade?: number | null;
  rightAnswers?: number | null;
  passingGrade?: number | null;
}

export type ParticipantStepV3 = {
  id: string;
  source: StepWithDelay;
} & {
  dateFrame: DateFrame;
  transitions: StepTransition[];
  quizSubmission?: QuizSubmission;
};

export type ParticipantStep = ParticipantStepV3;

export type ParticipantSectionV3 = {
  id: string;
  source: SectionWithSteps;
  steps: ParticipantStepV3[];
};

export type ParticipantSection = ParticipantSectionV3 & {
  waitingDate?: string;
};
