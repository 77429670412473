import React from 'react';

import { useControllerData } from '../../main/controllerContext';
import { FCWithChildren } from '@wix/challenges-web-library';
import { IChallengeContext } from './interfaces';

export function useChallengeData(): IChallengeContext {
  const props = useControllerData();

  return {
    isDescriptionShown: props.isDescriptionShown,
    challengeData: props.challengeData,
    challengeSections: props.challengeSections,
    challengeSteps: props.challengeSteps,
    requestChallengeSections: props.requestChallengeSections,
    requestChallengeSteps: props.requestChallengeSteps,
    requestInstructorsList: props.requestInstructorsList,
    instructorUrls: props.instructorUrls,
  };
}

export function withChallengeData<Props = any>(Component: FCWithChildren<any>) {
  return (props: Props) => {
    const programData = useChallengeData();
    return <Component {...props} {...programData} />;
  };
}
