import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import { IPaymentSettings } from './Settings/paymentSettings/paymentSettings.types';

export const paymentPageSettings = createSettingsParams<IPaymentSettings>({
  titleText: {
    type: SettingsParamType.Text,
    getDefaultValue({ t }) {
      return t('checkout.title');
    },
  },
  titleTag: {
    getDefaultValue() {
      return 'h1';
    },
  },
  displayTermsLink: {
    getDefaultValue() {
      return false;
    },
  },
  termsPageInfo: {
    type: SettingsParamType.Text,
    getDefaultValue() {
      return {};
    },
  },
});

export default paymentPageSettings;
