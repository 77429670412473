import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
  wixFontParam,
  IWixStyleFont,
} from '@wix/tpa-settings';
import { IPaymentStyles } from './Settings/paymentStyles/paymentStyles.types';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const booleans = styleParams.booleans;
  return Object.keys(booleans).reduce((result, key) => {
    result[key] = booleans[key] ? 'unset' : 'none';
    return result;
  }, {} as Record<string, string>);
};

export const fontValueFix = (fontFn, textPresets): IWixStyleFont => {
  const { value: _, ...font } = fontFn({ textPresets });

  return {
    ...font,
  } as IWixStyleFont;
};

const stylesParams = createStylesParams<IPaymentStyles>({
  nameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 32,
        }),
        textPresets,
      );
    },
  },
  nameFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

export default stylesParams;
export type IPaymentStylesKeys = keyof IPaymentStyles;
