import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import {
  Challenge,
  DurationUnit,
  SelfPaced,
  TimeDuration,
} from '@wix/ambassador-challenges-v1-challenge/types';

import {
  getChallengePaidPlans,
  isChallengeFree,
  IUserPaidPlan,
} from '@wix/challenges-web-library';
import { IUserType } from '../contexts/User/UserContext';
import {
  isUserInPaymentState,
  isUserJoinedAlready,
} from '../contexts/User/helpers/userTypeHandlers';
import endOfToday from 'date-fns/endOfToday';
import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import {
  getRightDateFromBackend,
  getRightDateFromBackendDateTime,
} from './dates';
import { isPrivateChallenge } from './isPrivateChallenge';
import { isFlexible } from './isFlexible';
import { isSpecific } from './isSpecific';
import { isSelfPaced } from './isSelfPaced';

export type TimelineType = 'ongoing' | 'flexible' | 'specific' | 'selfPaced';

export const isOngoing = (challenge: Challenge): boolean =>
  Boolean(challenge?.settings?.timelineType?.ongoing);
export const getFlexibleSettings = (challenge: Challenge): SelfPaced =>
  challenge?.settings?.timelineType?.flexible;
export const getSelfPacedSettings = (challenge: Challenge): SelfPaced =>
  challenge?.settings?.timelineType?.selfPaced;
export const getSpecificSettings = (challenge: Challenge): SelfPaced =>
  challenge?.settings?.timelineType?.specific;

export const isDurationExpired = (
  startDate: string, // api-related format: '2020-10-06'
  duration: TimeDuration,
): boolean => {
  if (startDate && duration?.unit && !isNaN(duration?.value)) {
    const fixedStartDated = new Date(getRightDateFromBackend(startDate));
    const durationInDays = getDurationInDays(duration);

    if (isAfter(new Date(), addDays(fixedStartDated, durationInDays))) {
      return true;
    }
  }

  return false;
};

export const getDurationInDays = (duration: TimeDuration): number => {
  if (!duration?.unit || isNaN(duration?.value)) {
    return 0;
  }

  switch (duration.unit) {
    case DurationUnit.MINUTES:
      return Math.floor(duration.value / 24 / 60);
    case DurationUnit.HOURS:
      return Math.floor(duration.value / 24);
    case DurationUnit.DAYS:
      return duration.value;
    case DurationUnit.WEEKS:
      return duration.value * 7;
    default:
      return 0;
  }
};

export const getDurationForFlexyTimelines = (
  challenge: Challenge,
): TimeDuration => {
  return (
    getFlexibleSettings(challenge)?.duration ||
    getSelfPacedSettings(challenge)?.duration ||
    (isSpecific(challenge) && getSpecificSettings(challenge)?.duration)
  ); // duration is relevant only for flexible or self-paced here
};

export const isNeedToSelectStartDate = (
  challenge: Challenge,
  userType: IUserType,
  userPaidPlans: IUserPaidPlan[],
) => {
  const challengePaidPlans = getChallengePaidPlans(challenge.id, userPaidPlans);

  if (isFlexible(challenge)) {
    if (userType === ParticipantState.INVITED) {
      return true;
    }

    if (userType === ParticipantState.JOIN_REQUESTED) {
      return false;
    }

    return !(
      isPrivateChallenge(challenge) &&
      !isChallengeFree(
        challenge?.settings?.pricing as any,
        challengePaidPlans.length,
      ) &&
      !isUserInPaymentState(userType)
    );
  }

  if (isSelfPaced(challenge)) {
    return !!challenge?.settings?.timelineType?.selfPaced?.duration?.value;
  }

  return false;
};

export const isPricingPickingForbidden = (
  challenge: Challenge,
  userType: IUserType,
  userPaidPlans: IUserPaidPlan[],
): boolean => {
  const challengePaidPlans = getChallengePaidPlans(challenge.id, userPaidPlans);

  return (
    isPrivateChallenge(challenge) &&
    !isChallengeFree(
      challenge?.settings?.pricing as any,
      challengePaidPlans.length,
    ) &&
    !isUserInPaymentState(userType)
  );
};

export function isChallengeAlreadyStarted(
  challenge: Challenge,
  participantState?: ParticipantState,
): boolean {
  const startTime = isSpecific(challenge)
    ? challenge?.settings?.timelineType?.specific?.startTime
    : false;

  if (!startTime || isUserJoinedAlready(participantState)) {
    return false;
  }

  return isAfter(
    endOfToday(),
    endOfDay(getRightDateFromBackendDateTime(startTime)),
  );
}
