import React from 'react';
import { FCWithChildren } from '@wix/challenges-web-library';

import { useGeneralData } from './GeneralDataContext';

export function withGeneralData<T = any>(Component: any): FCWithChildren<T> {
  return (props) => {
    const generalData = useGeneralData();

    return <Component {...(props as T)} {...generalData} />;
  };
}
