import { useControllerData } from '../../contexts/main/controllerContext';
import { usePromisify } from './usePromisify';
import { ExtractRequestOptionsData } from './types';

export const usePromisifyAmbassadorRequest = <
  T extends (...args: any[]) => any,
>(
  requestBuilder: T,
  functionName: string,
): ((
  opts: Parameters<T>[0],
) => Promise<ExtractRequestOptionsData<ReturnType<T>>>) => {
  const data = useControllerData();
  return usePromisify(data[functionName]);
};
