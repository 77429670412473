import { useEnvironment } from '@wix/yoshi-flow-editor';

export const useIsFullWithInViewer = () => {
  const { dimensions, isEditorX } = useEnvironment();

  if (isEditorX) {
    return false;
  }

  return String(dimensions.width) === '100%' || String(dimensions.width) === '';
};
