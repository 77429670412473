import { useControllerData } from '../../../contexts/main/controllerContext';

export const usePaymentControllerData = (): ReturnType<
  typeof useControllerData
> & {
  hasCouponsForPrograms?: boolean;
  termsAndConditionsUrl?: string;
} => {
  return useControllerData();
};
