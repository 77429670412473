import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

const SLUG_BLACK_LIST = ['.', 'wix-thunderbolt'];

export async function getChallengeSlugFromLocation(flowAPI: ControllerFlowAPI) {
  const { isViewer } = flowAPI.environment;
  const location = flowAPI.controllerConfig.wixCodeApi.location;
  let path = location.path;

  let slug: string;

  if (
    flowAPI.experiments.enabled('spec.programs.HandleCustomizedUrlSegments') && !flowAPI.environment.isEditor && !flowAPI.environment.isPreview
  ) {
    const urlData =
      await flowAPI.controllerConfig.wixCodeApi.site.getCustomizedUrlSegments(
        flowAPI.controllerConfig.wixCodeApi.location.url,
      );
    if (urlData?.segments?.slug) {
      return urlData?.segments?.slug;
    }
  }

  if (
    location.path.length <= 1 ||
    ['ChallengesList', 'ChallengesListPage'].includes(flowAPI.componentName)
  ) {
    return '';
  } else if (location.path.includes('challenges-web-ooi')) {
    // path in iframe editor and viewer is ["prod", "editor", "challenges-web-ooi", "1.1880.0", "ChallengesPage", <challenge id which is optional>]
    path = path.slice(5, 10);
  }
  if (isViewer) {
    slug = path[1];
  } else {
    slug = path[location.path.length - 1];
  }

  const isSlugInBlackList = SLUG_BLACK_LIST.some((item) => {
    return slug?.includes(item);
  });

  if (isSlugInBlackList) {
    // flowAPI.errorMonitor?.captureMessage(`Malformed slug: ${slug}`, {
    //   level: Severity.Error,
    //   tags: { location: 'getChallengeSlugFromLocation' },
    // });
    return null;
  }
  return slug;
}
