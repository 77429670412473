import React, { useCallback, useMemo } from 'react';
import flatten from 'lodash/flatten';

import {
  IIsStepHiddenContext,
  IsStepHiddenContext,
} from './IsStepHiddenContext';
import { useSections } from '../ParticipantSections/ParticipantSectionsContext';
import {
  getFirstAvailableStep,
  isStepHiddenOptimized,
  isVisibleStepLockedForCompleteOptimized,
} from '../../components/ParticipantPage/Widget/views/utils';
import { useParticipantSteps } from '../ParticipantStepsDataProvider/ParticipantStepsContext';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useControllerData } from '../main/controllerContext';
import { ParticipantStep } from '../../types/v3Types';
// todo use only on the participant page
export const IsStepHiddenProvider: FCWithChildren<IIsStepHiddenContext> = (
  props,
) => {
  const { challengeData } = useControllerData();
  const { listParticipantSections = [] } = useSections();
  const { participantSteps } = useParticipantSteps();

  const firstAvailableStep = useMemo(() => {
    const flatStepsList = flatten<ParticipantStep>(
      listParticipantSections.map((section) => section.steps || []),
    );
    return getFirstAvailableStep(flatStepsList);
  }, [listParticipantSections, participantSteps]);

  const isStepHidden = useCallback(
    (step: ParticipantStep) => {
      return isStepHiddenOptimized(
        challengeData?.challenge,
        step,
        firstAvailableStep,
      );
    },
    [challengeData?.challenge, firstAvailableStep],
  );

  const isVisibleStepLockedForComplete = useCallback(
    (step: ParticipantStep) => {
      return isVisibleStepLockedForCompleteOptimized(
        challengeData?.challenge,
        step,
        firstAvailableStep,
      );
    },
    [challengeData?.challenge, firstAvailableStep],
  );
  return (
    <IsStepHiddenContext.Provider
      value={{
        isStepHidden,
        isVisibleStepLockedForComplete,
      }}
    >
      {props.children}
    </IsStepHiddenContext.Provider>
  );
};
