import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function buildProgramPageUrl(flowAPI: ControllerFlowAPI, slug: string) {
  if (!flowAPI.environment.isViewer) {
    return '';
  }
  return flowAPI.controllerConfig.wixCodeApi.location.buildCustomizedUrl(
    'wix.online_programs.sub_pages.online_program',
    {
      slug,
    },
    {},
  );
}
