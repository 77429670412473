import {
  CHALLENGES_CLASSIC_CHANGED,
  CHALLENGES_EDITORX,
} from '../../components/ChallengesList/Widget/mocks';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getMockedChallenges = (flowAPI: ControllerFlowAPI) => {
  const { isEditorX } = flowAPI?.environment || {};

  let mockedChallenges = CHALLENGES_CLASSIC_CHANGED;

  if (isEditorX) {
    mockedChallenges = CHALLENGES_EDITORX;
  }

  return {
    memberChallenges: mockedChallenges,
    totalCount: mockedChallenges.length,
  };
};

export const isMockedChallenge = (
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
) => {
  return !!getMockedChallenge(slugOrChallengeId, flowAPI);
};

export const getMockedChallenge = (
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
) => {
  const mockedChallenge = getMockedChallenges(flowAPI)?.memberChallenges?.find(
    (challenge) => challenge?.challenge?.id === slugOrChallengeId,
  );

  return mockedChallenge?.challenge;
};
