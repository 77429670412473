export enum ServerKnownErrorCode {
  Auth = 'authorization_error',
  AccessError = 'access_error',
  SectionNotFound = 'section_not_found',
  StepNotFound = 'step_not_found',
  DuplicatedParticipantOwner = 'duplicated_participant_owner',
  DuplicatedParticipantMember = 'duplicated_participant_member',
  ApproveAlreadyResolved = 'approval_already_resolved',
}

export interface IApplicationError {
  code?: ServerKnownErrorCode;
  description?: string;
}

export interface IServerErrorMessage {
  data?: {
    details?: {
      traceId?: string;
      applicationError?: IApplicationError;
    };
    message?: string;
  };
}
