import { OP_APP_DEF_ID } from '../appDefId';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { CHALLENGE_PAGE_ID, PARTICIPANT_PAGE_ID } from '../editor/app-config';

let isParticipantPageInstalled_ = null;
export const isParticipantPageInstalled = async (
  flowAPI: ControllerFlowAPI,
) => {
  if (isParticipantPageInstalled_ === null) {
    isParticipantPageInstalled_ =
      !!(await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: OP_APP_DEF_ID,
        sectionId: PARTICIPANT_PAGE_ID,
      }));
  }

  return isParticipantPageInstalled_;
};

let challengePageSectionUrl = null;
export const getChallengePageSectionUrl = async (
  flowAPI: ControllerFlowAPI,
) => {
  if (challengePageSectionUrl === null) {
    challengePageSectionUrl =
      await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        appDefinitionId: OP_APP_DEF_ID,
        sectionId: CHALLENGE_PAGE_ID,
      });
  }

  return challengePageSectionUrl;
};

let participantPageSectionUrl = null;
export const getParticipantPageSectionUrl = async (
  flowAPI: ControllerFlowAPI,
) => {
  if (participantPageSectionUrl === null) {
    participantPageSectionUrl =
      await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
        appDefinitionId: OP_APP_DEF_ID,
        sectionId: PARTICIPANT_PAGE_ID,
      });
  }

  return participantPageSectionUrl;
};
