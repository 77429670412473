import { QuizSubmission } from '@wix/ambassador-challenges-v1-participant/types';
import { useSections } from '../ParticipantSections/ParticipantSectionsContext';
import {
  ParticipantSection,
  ParticipantStep,
  StepTransition,
} from '../../types/v3Types';

export type UpdateParticipantStepStatusPayload = {
  sections: ParticipantSection[];
  steps: ParticipantStep[];
  stepId: string;
  transitions?: StepTransition[];
  quizSubmission?: QuizSubmission;
  replace?: boolean;
};

export const useParticipantSteps = () => {
  return useSections();
};
