import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';

export function getParticipantOrderId(participant: Participant): string {
  if (participant) {
    switch (participant.transitions?.[0]?.state) {
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.SUSPENDED:
        return participant.orderIds?.[0];
      default:
      case ParticipantState.PAYMENT_REQUESTED:
        return '';
    }
  }
  return '';
}
