export const enum Referrer {
  THANKYOU_PAGE = 'ThankYouPage.controller',
  PAYMENT_PAGE = 'PaymentPage.controller',
  PARTICIPANT_PAGE = 'ParticipantPage.controller',
  CHALLENGES_PAGE = 'ChallengesPage.controller',
  ALL = 'all',
}

export type StorageKey = 'requestChallenge' | 'User.getEmail';

export type StorageKeyWithId = `${StorageKey}:${string}`;

const ELIGIBLE_REFERRERS: { [key in StorageKey]: Referrer[] } = {
  'User.getEmail': [
    Referrer.THANKYOU_PAGE,
    Referrer.PAYMENT_PAGE,
    Referrer.CHALLENGES_PAGE,
    Referrer.PARTICIPANT_PAGE,
  ],
  requestChallenge: [Referrer.PAYMENT_PAGE],
};
export const isEligibleReferrer = (referrer: Referrer, key: StorageKey) => {
  return (
    referrer === Referrer.ALL || ELIGIBLE_REFERRERS[key].includes(referrer)
  );
};
