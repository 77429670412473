import React from 'react';
import { contextFactory } from '../../services/contextFactory';
import { ILoadingContext, ILoadingProps, ILoadingStatus } from './interfaces';

export const loadingDefaultValue = {
  isLoading: false,
  loadingStatus: ILoadingStatus.DEFAULT,
  hideLoader() {},
  showLoader() {},
};
const { ContextProvider, withConsumer, context } = contextFactory<
  ILoadingContext,
  ILoadingProps
>({
  defaultValues: loadingDefaultValue,
  displayName: 'LoadingProvider',
  propsToContext(props): ILoadingContext {
    return {
      ...props,
    };
  },
});

export const LoadingProvider = ContextProvider;

export function useLoading(): ILoadingContext {
  return React.useContext(context);
}

export const withLoadingAPI = withConsumer;
