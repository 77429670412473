import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';

export function getProgramSinglePaymentPrice(challenge: Challenge) {
  return challenge?.settings?.pricing?.singlePayment?.price;
}

export function getProgramSinglePaymentAmount(challenge: Challenge) {
  return getProgramSinglePaymentPrice(challenge)?.amount;
}

export function isProgramHasOneTimePayment(challenge: Challenge) {
  return Boolean(getProgramSinglePaymentAmount(challenge));
}
