import {
  Description,
  ParticipantStep,
  ParticipantStepState,
  Step,
  StepV3WithDelay,
  StepWithDelay,
} from '../types/v3Types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';
import { StepType } from '@wix/ambassador-online-programs-v3-step/types';

export const getDescription = (step: Step): Description => {
  return (step as StepV3)?.description || null;
};

export const getTitle = (step: Step) => {
  return getDescription(step)?.title;
};

export const getDetails = (step: Step) => {
  return getDescription(step)?.details;
};

export const getDelay = (step: StepWithDelay) => {
  return (step as StepV3WithDelay)?.delay;
};

export const getQuizId = (step: Step) => {
  return (step as StepV3)?.quizOptions?.id;
};

export const getStepType = (step: Step): StepType => {
  return (step as StepV3)?.stepType;
};

export const getQuizSubmissionId = (step: ParticipantStep) => {
  return step?.quizSubmission?.quizSubmissionId;
};

export const isFailed = (resolved: {
  transitions?: { state?: `${ParticipantStepState}` }[];
}) => {
  return (
    resolved?.transitions?.[0]?.state === ParticipantStepState.FAILED || false
  );
};
