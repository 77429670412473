export const CHALLENGE_PAGE_ID = 'challenge_page';
export const PAYMENT_PAGE_ID = 'Challenge Payment';
export const THANK_YOU_PAGE_ID = 'Thank You Page';
export const CHALLENGES_LIST_PAGE_ID = 'Challenges';
export const PARTICIPANT_PAGE_ID = 'Participant Page';

export const PAYMENT_PAGE_MANIFEST_KEY = 'paymentPage';
export const THANK_YOU_PAGE_MANIFEST_KEY = 'challengeThankYou';
export const CHALLENGES_LIST_PAGE_MANIFEST_KEY = 'challenges';
export const CHALLENGES_PAGE_MANIFEST_KEY = 'challenge_page_key';
export const PARTICIPANT_PAGE_MANIFEST_KEY = 'participant_page_key';

export const CHALLENGE_PAYMENT_PAGE_TYPE =
  '0daa3702-0e20-48ca-ba15-ad380e00e6b6';
export const CHALLENGE_THANK_YOU_PAGE_TYPE =
  'a159b4d2-831b-410c-9e77-ec3f40c9cace';
export const CHALLENGES_LIST_PAGE_TYPE = '20dcd94b-f847-4e24-b3f2-0d3894593f7a';
export const CHALLENGES_LIST_WIDGET_TYPE =
  'ef4e59bd-625a-484e-9ba1-650d4aecf03b';
export const MA_PROGRAMS_LIST_PAGE_TYPE =
  'c88902a1-7fbf-4ff1-b19a-af39c48bc740';
export const CHALLENGE_PAGE_TYPE = '847c2f5d-017f-499f-9c3e-069164fc116f';
export const PARTICIPANT_PAGE_TYPE = '0e9aad02-e5a7-45f3-8b8e-a842a5e99be3';
export const CHALLENGES_PAGES = [
  CHALLENGE_PAGE_ID,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_ID,
  CHALLENGES_LIST_PAGE_ID,
  PARTICIPANT_PAGE_ID,
];

export const CHALLENGES_PAGES_OLD_TITLES = {
  [CHALLENGE_PAGE_ID]: 'Challenge Page',
  [CHALLENGES_LIST_PAGE_ID]: 'Challenges',
  [PAYMENT_PAGE_ID]: 'Challenge Payment',
};

export const PAGE_TITLES = {
  [CHALLENGE_PAGE_ID]: 'editor.initial.page-name.challenge-page',
  [PAYMENT_PAGE_ID]: 'editor.initial.page-name.payment-page',
  [THANK_YOU_PAGE_ID]: 'editor.initial.page-name.thank-you-page',
  [CHALLENGES_LIST_PAGE_ID]: 'editor.initial.page-name.challenge-list-page',
  [PARTICIPANT_PAGE_ID]: 'editor.initial.page-name.participant-page',
};

export const NOINDEX_PAGES = [THANK_YOU_PAGE_ID, PAYMENT_PAGE_ID];

export const CHALLENGES_PLATFORM_CONTROLLER_TYPE = 'challengesPlatform';

export const PAID_PLANS_APP_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';
export const MY_WALLET_APP_ID = '4aebd0cb-fbdb-4da7-b5d1-d05660a30172';
export const MEMBERS_PROFILE_APP_ID = '14dbef06-cc42-5583-32a7-3abd44da4908';
export const SANTA_MEMBERS_APP_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const PP_SECTION_ID = 'membership_plan_picker_tpa';
export const MY_WALLET_WIDGET_ID = '6467c15e-af3c-4e8d-b167-41bfb8efc32a';
export const MY_WALLET_SECTION_ID = 'my_wallet';
export const MEMBERS_PROFILE_WIDGET_ID = '14dbefb9-3b7b-c4e9-53e8-766defd30587';
export const MEMBERS_PROFILE_SECTION_ID = 'about';

export type CHALLENGES_INSTALL_MIGRATION_TYPE =
  | 'install_pricing_plans'
  | 'install_groups';
