import type { IHttpError } from '@wix/http-client/dist/src/types';
import { IApplicationError, ServerKnownErrorCode } from './IServerErrorMessage';

export class ApplicationHTTPError extends Error implements IApplicationError {
  description: string;
  code: ServerKnownErrorCode;
  constructor(httpError: IHttpError) {
    super();
    const { code, description } =
      (httpError.response?.data?.details
        ?.applicationError as IApplicationError) || {};
    this.message = code || httpError.message;
    this.description = description;
    this.code = code;
    this.stack = httpError.stack;
  }
}
