import { IToastProviderProps } from './interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function toastPropsMap(flowApi: ControllerFlowAPI): IToastProviderProps {
  return {
    clearMessage() {
      flowApi.controllerConfig.setProps({
        message: undefined,
      });
    },
  };
}

export function showToastFromController(
  flowApi: ControllerFlowAPI,
  toastMessage: IToastProviderProps['toastMessage'],
) {
  const update: Pick<IToastProviderProps, 'toastMessage'> = { toastMessage };
  flowApi.controllerConfig.setProps(update);
}
