import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getUrlParams,
  isHomePage,
} from '../../Location/locationProviderPropsMap';
import requestChallenge from './api/requestChallenge';
import { isMA } from '../../../selectors/isMA';
import { ChallengeServerlessData } from '../../../types/challenge-serverless';
import { shouldReportErrorMonitor } from './helpers/shouldReportErrorMonitor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getChallengesListWithMocks } from '../ChallengesList';
import { defaultChallengeData, IChallengeContext } from './interfaces';
import { handleChallengeAfterLogin } from './helpers/handleChallengeAfterLogin';
import { requestInstructorsList } from './api';
import { Referrer } from '../../storage/referrer';
import { getProfilePageUrls } from './helpers/getProfilePageUrls';
import { getChallengeSlugFromLocation } from '../../Location/helpers/getChallengeSlugFromLocation';

import { getProgramSlug } from '../../../selectors/getProgramSlug';
import { WarmupData } from '../../../services/WarmupData';

// todo: add warmup

let CHALLENGE_DATA_PROMISE: Promise<ChallengeServerlessData> = null;

const getSlugFromList = async (flowAPI: ControllerFlowAPI): Promise<string> => {
  let challengeIdOrSlug = '';

  try {
    const challengesListData = await getChallengesListWithMocks(flowAPI);
    const firstChallenge = challengesListData?.memberChallenges[0]?.challenge;

    if (firstChallenge) {
      challengeIdOrSlug = getProgramSlug(firstChallenge);
    }
  } catch (err) {
    const resolvedError = flowAPI.errorHandler.getResolvedError(err);
    handleError({
      error: err,
      resolvedError,
      context: 'getChallengeIdFromList',
      preventErrorMonitorReport: !shouldReportErrorMonitor(err),
    });
  }

  return challengeIdOrSlug;
};

const withCache = <T extends Function>(fn: T): T => {
  return ((...args: any[]) => {
    if (!CHALLENGE_DATA_PROMISE) {
      CHALLENGE_DATA_PROMISE = fn(...args);
    }
    return CHALLENGE_DATA_PROMISE;
  }) as any as T;
};

const GET_CHALLENGE_KEY = 'GET_CHALLENGE_REQUEST';

export const getChallengeData = withCache(
  async (
    flowAPI: ControllerFlowAPI,
    referrer?: Referrer,
  ): Promise<ChallengeServerlessData> => {
    const warmupData = new WarmupData(flowAPI);
    const ssrData = warmupData.get(GET_CHALLENGE_KEY);

    if (
      ssrData &&
      flowAPI.experiments.enabled('specs.programs.OOIAddWarmupGetChallenge')
    ) {
      return ssrData;
    }

    const { isPreview, isEditor } = flowAPI.environment;
    const isPreviewOrEditor = isPreview || isEditor;

    let challengeIdOrSlug = await getChallengeSlugFromLocation(flowAPI);
    const shouldGetSlugFromList =
      !challengeIdOrSlug && (isPreviewOrEditor || isHomePage(flowAPI));
    if (shouldGetSlugFromList) {
      challengeIdOrSlug = await getSlugFromList(flowAPI);
    }

    if (challengeIdOrSlug && !isMA(flowAPI)) {
      try {
        const data = await requestChallenge(
          challengeIdOrSlug,
          flowAPI,
          referrer,
        );

        flowAPI.bi?.updateDefaults({
          challengeId: data?.challenge?.id,
        });

        warmupData.set(GET_CHALLENGE_KEY, data);

        return data;
      } catch (err) {
        const resolvedError = flowAPI.errorHandler.getResolvedError(err);
        if (!isPreviewOrEditor) {
          // needed to redecue count of failed calls when challenge is not returned.
          // this func called in many places

          if (err.response?.status === 404) {
            // send status for seo for robots
            flowAPI.controllerConfig.wixCodeApi.seo.setSeoStatusCode(404);
          }

          if (err.httpStatus !== 404 && err.response?.status !== 404) {
            handleError({
              error: err,
              resolvedError,
              context: 'getOwnerChallenge',
              preventErrorMonitorReport: !shouldReportErrorMonitor(err),
            });
          }
          return { challenge: {} };
        } else {
          console.error(err);
        }
      }
    }

    return defaultChallengeData;
  },
);

export const getChallengeInitialData = async (
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<IChallengeContext> => {
  const urlParams = getUrlParams(flowAPI);
  const challengeData = await getChallengeData(flowAPI, referrer);
  const instructorUrls = await getProfilePageUrls(
    flowAPI,
    challengeData?.instructorsData?.instructors || [],
  );
  void handleChallengeAfterLogin();

  return {
    isDescriptionShown: urlParams.navigationType === 'description',
    challengeData,
    instructorUrls,
    requestInstructorsList: async (idOrSlug: string): Promise<void> => {
      const _challengeData = challengeData;

      let instructors = null;

      try {
        instructors = await requestInstructorsList(idOrSlug, flowAPI);
      } catch (err) {
        const resolvedError = flowAPI.errorHandler.getResolvedError(err);
        handleError({
          error: err,
          resolvedError,
          context: 'requestInstructorsList',
          preventErrorMonitorReport: !shouldReportErrorMonitor(err),
          muteError: true,
        });
        console.error(err);
      }

      flowAPI.controllerConfig.setProps({
        challengeData: { ..._challengeData, instructorsData: instructors },
      });
    },
  };
};

export const resetChallengeCache = () => {
  CHALLENGE_DATA_PROMISE = null;
};
