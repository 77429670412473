import { getFirstAvailableStep } from '../components/ParticipantPage/Widget/views/utils';
import {
  Section,
  SectionWithSteps,
  Description,
  ParticipantSection,
  ParticipantStep,
} from '../types/v3Types';

export const isSectionLocked = (section: ParticipantSection): boolean => {
  return !!getWaitingDate(section);
};

export const getFirstAvailableStepFromSection = (
  sections: ParticipantSection[] = [],
): ParticipantStep => {
  let step: ParticipantStep;

  sections.forEach((section) => {
    if (step || isSectionLocked(section)) {
      return;
    }

    step = getFirstAvailableStep(section.steps);
  });

  return step;
};

export const getSectionDescription = (section: Section): Description => {
  return section?.description;
};

export const getSectionSteps = (section: SectionWithSteps) => {
  if (!section.steps) {
    console.warn('Section has no steps', section);
  }
  return section.steps;
};

export const getSectionTitle = (section: Section) => {
  return getSectionDescription(section)?.title;
};

export const getWaitingDate = (section: ParticipantSection) => {
  return section?.waitingDate;
};

export const getWaitingStart = (section: ParticipantSection) => {
  return getWaitingDate(section);
};
