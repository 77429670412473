import { IUserType } from '../UserContext';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { ParticipantV1 } from '../../../types/v3Types';
import { getParticipantState } from '../../../selectors/participant/getState';

// User already did one of actions according to join the challenge.
export const isUserTouchedChallenge = (userType: ParticipantState) => {
  return [
    ParticipantState.FAILED,
    ParticipantState.REMOVED,
    ParticipantState.JOIN_REJECTED,
    ParticipantState.COMPLETED,
    ParticipantState.RUNNING,
    ParticipantState.JOINED,
    ParticipantState.INVITED,
    ParticipantState.JOIN_REQUESTED,
    ParticipantState.LEFT,
    ParticipantState.PAYMENT_REQUESTED,
    ParticipantState.PAYMENT_STARTED,
    ParticipantState.SUSPENDED,
  ].includes(userType);
};

// User is in the payment state.
export const isUserInPaymentState = (userType: IUserType) => {
  return [
    ParticipantState.PAYMENT_REQUESTED,
    ParticipantState.PAYMENT_STARTED,
  ].includes(userType as ParticipantState);
};

// User successfully joined the challenge and maybe even completed it already.
export const isUserJoinedAlready = (userType: IUserType) => {
  return [
    ParticipantState.COMPLETED,
    ParticipantState.RUNNING,
    ParticipantState.JOINED,
    ParticipantState.FAILED,
    ParticipantState.SUSPENDED,
  ].includes(userType as ParticipantState);
};

export const isParticipantAlreadyJoined = (participant: ParticipantV1) => {
  return isUserJoinedAlready(getParticipantState(participant));
};

export const isUserJoinedAlreadyWithoutSuspended = (userType: IUserType) => {
  return (
    isUserJoinedAlready(userType) && userType !== ParticipantState.SUSPENDED
  );
};

// User completed the challenge
export const isUserCompleted = (userType: IUserType) => {
  return [ParticipantState.COMPLETED, ParticipantState.FAILED].includes(
    userType as ParticipantState,
  );
};
