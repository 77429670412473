export const isCorrectStringDate = (
  date: string,
  separator = '/',
  reverse = false,
): Boolean => {
  const pattern = reverse
    ? `\\d\\d\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d`
    : `\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d\\d\\d`;

  return new RegExp(pattern).test(date);
};

export const getDateFromString = (
  date: string,
  separator = '/',
  reverse = false,
): Date => {
  const isCorrectDate = isCorrectStringDate(date, separator, reverse);
  let result = null;

  if (isCorrectDate) {
    const parsed = date.split(separator);
    const day = reverse ? parseInt(parsed[2], 10) : parseInt(parsed[0], 10);
    const month = parseInt(parsed[1], 10) - 1;
    const year = reverse ? parseInt(parsed[0], 10) : parseInt(parsed[2], 10);

    result = new Date(year, month, day);
  }

  return result;
};

export const getRightDateFromBackend = (date: string): Date => {
  try {
    return getDateFromString(date, '-', true);
  } catch (e) {
    return new Date(date);
  }
};

export const getRightDateFromBackendDateTime = (date: any): Date => {
  return new Date(date.year, date.month - 1, date.day);
};
