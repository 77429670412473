import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { HttpResponse } from '@wix/fe-essentials/dist/types/exports/http-client';
import { getInstance } from './instance';
import { ErrorCodesMapConditionalEnforcement } from '@wix/error-handler/dist/types/types/ErrorCodesMap';
import type { RequestOptionsFactory } from '@wix/http-client';
import { ExtractRequestOptionsData } from './asyncController/types';
import { ServerKnownErrorCode } from '../contexts/ErrorHandler/IServerErrorMessage';

export async function request<Options extends RequestOptionsFactory<any>>({
  flowAPI,
  options,
  additionalHeaders,
  errorOptions,
  ignoreError,
}: {
  flowAPI: ControllerFlowAPI;
  options: Options;
  additionalHeaders?: Record<'Authorization', string>;
  ignoreError?: boolean;
  errorOptions: {
    errorCodesMap: Omit<
      ErrorCodesMapConditionalEnforcement<
        HttpResponse<ExtractRequestOptionsData<Options>>
      >,
      'validationError'
    >;
  };
}): Promise<HttpResponse<ExtractRequestOptionsData<Options>>> {
  const instanceFromUrl = getInstance(flowAPI);
  const t = flowAPI.translations.t;
  const { withErrorHandler } = flowAPI.errorHandler;

  const promise = withErrorHandler(
    async () => {
      return flowAPI.httpClient.request(function (context) {
        const resolvedOptions =
          typeof options === 'function' ? options(context) : options;

        return {
          ...resolvedOptions,
          headers: {
            ...resolvedOptions.headers,
            ...additionalHeaders,
            ...getMLHeaders(flowAPI),
            Authorization: instanceFromUrl,
          },
        };
      } as Options);
    },
    {
      ...(errorOptions || {}),
      errorCodesMap: {
        ...(errorOptions?.errorCodesMap || {}),
        applicationError: {
          // keep old response codes and messages
          [ServerKnownErrorCode.Auth]: () => {
            return { message: t('toast.error.permission-denied') };
          },
          [ServerKnownErrorCode.DuplicatedParticipantOwner]: () => {
            return { message: t('toast.error.duplicate-owner') };
          },
          [ServerKnownErrorCode.DuplicatedParticipantMember]: () => {
            return { message: t('toast.error.duplicate-member') };
          },
          [ServerKnownErrorCode.ApproveAlreadyResolved]: () => {
            return { message: t('toast.error.resolved') };
          },
          [ServerKnownErrorCode.AccessError]: () => {
            return { message: t('toast.error.server-general') };
          },
          [ServerKnownErrorCode.SectionNotFound]: () => {
            return { message: t('toast.error.server-general') };
          },
          [ServerKnownErrorCode.StepNotFound]: () => {
            return { message: t('toast.error.server-general') };
          },
        },
        ...(errorOptions?.errorCodesMap?.applicationError || {}),
      },
    },
  );

  if (ignoreError) {
    promise.catch((error) => {
      console.error(flowAPI.errorHandler.getResolvedError(error).message);
    });
  }

  return promise;
}

export const getMLHeaders = (flowAPI?: Partial<ControllerFlowAPI>) => {
  const headers = {};

  // We need to pass ML header to our BE if its enabled.
  if (flowAPI?.environment?.multilingual.isEnabled) {
    const currMLLanguage = flowAPI.environment.multilingual.siteLanguages.find(
      (lang) =>
        lang?.languageCode === flowAPI.environment.multilingual.currentLanguage,
    );

    if (currMLLanguage) {
      headers[
        'x-wix-linguist'
      ] = `${currMLLanguage?.languageCode}|${currMLLanguage?.locale}|${currMLLanguage?.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`;
    }
  }

  return headers;
};
