import React, { useCallback } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Member } from '@wix/ambassador-members-v1-member/types';
import { withUser } from '../../../../contexts/User/withUser';
import { IUserProviderProps } from '../../../../contexts/User/UserProvider';
import { ICashierPaymentsApi } from '@wix/cashier-payments-widget';
import {
  IChallengeContext,
  withChallengeData,
} from '../../../../contexts/storage-contexts/Challenge';
import { withLocation } from '../../../../contexts/Location/withLocation';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';
import { useGeneralData } from '../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { applyProviders } from '../../../../services/applyProviders';
import { DeviceType } from '../../../../editor/types/common';
import { UserState } from '../../../../contexts/User/UserContext';
import { usePromisifyAmbassadorRequest } from '../../../../services/asyncController/usePromisifyAmbassadorRequest';
import { FCWithChildren } from '@wix/challenges-web-library';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { PaymentWidget } from './PaymentWidget';
import { getParticipantOrderId } from '../../../../selectors/participants';
import { Text, TextPriority, TextTypography } from 'wix-ui-tpa/cssVars';
import { getProgramSlug } from '../../../../selectors/getProgramSlug';
import { usePaymentControllerData } from '../../hooks/UsePaymentControllerData';
import challengeSettings from '../../settingsParams';

import { classes } from './PaymentWidget.st.css';
import { getProgramSinglePaymentPrice } from '../../../../selectors/payment/oneTimePayment';
import { useGetResolvedError } from '../../../../services/asyncController/useGetResolvedError';

export interface IContainerProps {
  onApiReady(api: ICashierPaymentsApi): void;
  onWidgetLoaded(): void;
  hideTitle?: boolean;
}

let requestInProgress = false;

type IPaymentContainerProps = IUserProviderProps &
  IContainerProps &
  ILocationProviderProps &
  IChallengeContext;

export const PaymentWidgetContainer: FCWithChildren<IContainerProps> =
  applyProviders(
    (props: IPaymentContainerProps) => {
      const data = usePaymentControllerData();
      const { appDefinitionId, language, isMobile, isViewer, isEditor } =
        useEnvironment();
      const settings = useSettings();
      const {
        instanceId,
        instance,
        host,
        msid,
        defaultBIParams: { siteOwnerId },
      } = useGeneralData();
      const { isMyWalletInstalled } = useGeneralData();
      const challenge = props.challengeData?.challenge || {};
      const slug = getProgramSlug(challenge);
      const getMember = usePromisifyAmbassadorRequest(
        getMyMember,
        'getMyMember',
      );
      const getResolvedError = useGetResolvedError();
      const { query } = useLocation();
      const [currentMember, setCurrentMember] = React.useState<Member>(null);
      const styles = useStyles();

      React.useEffect(() => {
        async function init() {
          try {
            const member = await getMember({});

            setCurrentMember(member.member);
          } catch (error) {
            getResolvedError(error).then((er) => {
              console.error('Error on get current member:', er.message);
            });
          }
        }

        if (isViewer) {
          init();
        }
      }, [getMember, isViewer]);

      if (!slug && isViewer) {
        return null;
      }

      const amount = getProgramSinglePaymentPrice(challenge)?.amount;
      const currency = getProgramSinglePaymentPrice(challenge)?.currency;

      const orderId = getParticipantOrderId(props.participant) || query.orderId;

      if (!props.user || (!orderId && isViewer)) {
        props.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });

        return null;
      }

      if (!props.participant && !requestInProgress && isViewer) {
        requestInProgress = true;
      }

      const openThankYouPage = useCallback(() => {
        props.goToPage({
          pageId: Pages.ThankYou,
          challengeId: slug,
          queryParams: {
            showOneAppInfo: query?.showOneAppInfo,
          },
        });
      }, [props, query, slug]);

      return (
        <div
          data-hook="payment-widget-container"
          className={classes.widgetContainer}
        >
          {!isMobile && !props.hideTitle && (
            <Text
              priority={TextPriority.primary}
              tagName={settings.get(challengeSettings.titleTag)}
              typography={TextTypography.largeTitle}
              className={classes.title}
            >
              {settings.get(challengeSettings.titleText) as string}
            </Text>
          )}
          <PaymentWidget
            styles={styles}
            settings={settings}
            termsAndConditionsUrl={data.termsAndConditionsUrl}
            currentMember={currentMember}
            siteOwnerId={siteOwnerId}
            appId={appDefinitionId}
            userId={props.user.id}
            instanceId={instanceId}
            instance={instance}
            host={host}
            domain={isEditor && 'https://editor.wix.com'}
            deviceType={isMobile ? DeviceType.Mobile : DeviceType.Desktop}
            msid={msid}
            locale={language}
            orderId={orderId}
            onApiReady={props.onApiReady}
            onWidgetLoaded={() => {
              props.onWidgetLoaded();
            }}
            amount={amount}
            currency={currency}
            pendingOwnerApprove={() => {
              props.navigateToProgramPage({
                challengeId: slug,
                isParticipant: true,
                queryParams: {
                  forceState: UserState.PAYMENT_PENDING,
                },
              });
            }}
            paymentComplete={openThankYouPage}
            isMyWalletInstalled={isMyWalletInstalled}
          />
        </div>
      );
    },
    [withChallengeData, withLocation, withUser],
  );

PaymentWidgetContainer.displayName = 'PaymentWidgetContainer';
