import React, { createContext, useState } from 'react';

export enum FocusPoint {
  Top = 'Top',
  Bottom = 'Bottom',
}

interface Focusable {
  focus(): void;
}

export interface IFocusManager {
  setNextFocusPoint(point: FocusPoint): void;
  focusPoint: FocusPoint;
  moveFocusToPoint(focusable: Focusable, point: FocusPoint): void;
}

export const FocusManagerContext = createContext<IFocusManager>({
  setNextFocusPoint: null,
  focusPoint: null,
  moveFocusToPoint: null,
});

export const FocusManagerProvider = ({ children }) => {
  const [focusPoint, setNextPoint] = useState<FocusPoint>(FocusPoint.Top);
  const moveFocusToPoint = (focusable: Focusable, point: FocusPoint) => {
    if (point === focusPoint) {
      focusable.focus();
      setNextPoint(null);
    }
  };
  return (
    <FocusManagerContext.Provider
      value={{ setNextFocusPoint: setNextPoint, focusPoint, moveFocusToPoint }}
    >
      {children}
    </FocusManagerContext.Provider>
  );
};

export const useFocusManager = () => {
  const { setNextFocusPoint, focusPoint, moveFocusToPoint } =
    React.useContext(FocusManagerContext);
  return {
    setNextFocusPoint,
    focusPoint,
    moveFocusToPoint,
  } as IFocusManager;
};
