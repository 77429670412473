// value en-US, es-AR...
export function getCountryCode(value: string) {
  if (!value) {
    return null;
  }

  const matches = value.split('-');

  return matches[matches.length - 1];
}
