import { useCallback, useEffect, useRef } from 'react';
import { useControllerData } from '../../contexts/main/controllerContext';
import uuid from 'uuid';
import { PromiseStatus } from './types';

export const usePromisify = <T extends (...args: any[]) => Promise<any>>(
  fn: T,
): T => {
  const subscribers = useRef<
    {
      id: string;
      response: (data: Awaited<ReturnType<T>>) => void;
      reject: (error: unknown) => void;
      status: PromiseStatus;
    }[]
  >([]);
  const data = useControllerData().__promise;

  useEffect(() => {
    if (!data) {
      return;
    }
    subscribers.current = subscribers.current.filter((sub) => {
      const promiseState = data[sub.id];
      const status = promiseState?.status;
      if (promiseState) {
        if (status !== sub.status) {
          sub.status = promiseState.status;
          switch (promiseState.status) {
            case 'resolved':
              sub.response(promiseState.result);
              return false; // remove the subscriber
            case 'rejected':
              sub.reject(sub.id);
              return false; // remove the subscriber
          }
        }
      }
      return true;
    });
  }, [data]);

  return useCallback(
    (...args: any[]) => {
      return new Promise((resolve, reject) => {
        const id = uuid();
        subscribers.current.push({
          id,
          response: resolve,
          reject,
          status: 'pending',
        });
        fn(id, ...args);
      });
    },
    [fn],
  ) as T;
};
