import React, { useEffect } from 'react';

import { ToastContext } from './ToastContext';
import { Toast } from '../../components-shared/Toast';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { IToastProviderProps, ToastType } from './interfaces';
import { FCWithChildren } from '@wix/challenges-web-library';

export const ToastProvider: FCWithChildren<IToastProviderProps> = (props) => {
  const [opened, setOpened] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<any>(null);
  const [theme, setTheme] = React.useState(ToastType.success);
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { toastMessage } = props;
  const showToast = React.useCallback((_content, opts) => {
    setOpened(true);
    setContent(_content);
    setTheme(opts?.type);
  }, []);

  useEffect(() => {
    if (toastMessage) {
      showToast(
        toastMessage.message || t(toastMessage.key, toastMessage.payload),
        {
          type: toastMessage.type,
        },
      );
    }
  }, [showToast, t, toastMessage]);

  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      <Toast
        isMobile={isMobile}
        theme={theme}
        onClose={() => {
          if (toastMessage) {
            props.clearMessage?.();
          }
          setOpened(false);
        }}
        isShown={opened}
        shouldAnimate={true}
        shouldShowCloseButton={true}
      >
        {content}
      </Toast>
      {props.children}
    </ToastContext.Provider>
  );
};
