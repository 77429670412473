import React from 'react';
import { FCWithChildren } from '@wix/challenges-web-library';

interface ContextFactoryOptions<ContextProps, ProviderProps> {
  defaultValues: ContextProps;
  displayName: string;
  Wrapper?(props: { children: any } & ContextProps): React.ReactNode;
  propsToContext(props: ProviderProps): ContextProps;
}

interface ContextFactoryReturnType<ProviderProps, ContextProps> {
  ContextProvider: FCWithChildren<ProviderProps>;
  context: React.Context<ContextProps>;
  withConsumer(Component: any): any;
}

export function contextFactory<ContextProps, ProviderProps>(
  options: ContextFactoryOptions<ContextProps, ProviderProps>,
): ContextFactoryReturnType<ProviderProps, ContextProps> {
  const context = React.createContext<ContextProps>(options.defaultValues);
  context.displayName = options.displayName;

  const { Consumer, Provider } = context;
  const ContextProvider: FCWithChildren<ProviderProps> = (props) => (
    <Provider value={options.propsToContext(props)}>{props.children}</Provider>
  );
  ContextProvider.displayName = `${options.displayName}ContextProvider`;
  const withConsumer = (Component: any) => {
    const displayName = `with${options.displayName}`;
    const WithConsumerComponent: FCWithChildren<any> = (props) => {
      return (
        <Consumer>
          {(value) => {
            return <Component {...(props as any)} {...value} />;
          }}
        </Consumer>
      );
    };
    WithConsumerComponent.displayName = displayName;
    return WithConsumerComponent;
  };

  return {
    withConsumer,
    ContextProvider,
    context,
  };
}
