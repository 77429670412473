import React from 'react';
import { ISinglePaymentPageProps } from './interfaces';
import { PaymentWidgetContainer } from '../components/PaymentWidget/PaymentWidgetContainer';
import { PaymentCheckoutContainer } from '../components/PaymentCheckout/PaymentCheckoutContainer';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ApplyCouponToOrderResponse } from '@wix/ambassador-challenges-v1-participant/types';
import { PaymentJoinButton } from '../components/PaymentJoinButton/PaymentJoinButton';
import { FCWithChildren } from '@wix/challenges-web-library';
import { classes, st } from './Widget.st.css';
import { useUser } from '../../../contexts/User/UserContext';
import { Divider, Text, TextButton, TextTypography } from 'wix-ui-tpa/cssVars';
import { getParticipantOrderId } from '../../../selectors/participants';
import { useLocation } from '../../../contexts/Location/LocationContext';

export const PaymentPageV1: FCWithChildren<ISinglePaymentPageProps> = () => {
  const { isMobile, isRTL } = useEnvironment();
  const { t } = useTranslation();
  const { user, promptLogin, participant } = useUser();
  const { query } = useLocation();
  const loggedIn = !!user?.loggedIn;
  const orderId = getParticipantOrderId(participant) || query.orderId;

  const [coupon, setCoupon] = React.useState<ApplyCouponToOrderResponse>(null);
  const isCouponZeroPrice = parseFloat(coupon?.total) === 0;
  const [renderPaymentWidget, setRenderPaymentWidget] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    // render payment widget after hydration
    if (orderId && loggedIn) {
      setRenderPaymentWidget(true);
      window?.scrollTo?.(0, 0);
    }
  }, [orderId, loggedIn]);

  return (
    <section
      data-hook="challenges-payment-page"
      className={st(classes.root, {
        mobile: isMobile,
        rtl: isRTL,
      })}
    >
      {isCouponZeroPrice ? (
        <div className={classes.paymentWidgetWrapper}>
          <PaymentJoinButton />
        </div>
      ) : (
        <div className={classes.paymentWidgetWrapper}>
          <Text typography={TextTypography.largeTitle}>
            1. Customer Details
          </Text>
          {loggedIn ? (
            <div className={classes.paymentWidgetWrapper}>
              <Text typography={TextTypography.runningText}>
                You're logged in
              </Text>
            </div>
          ) : (
            <div>
              <Text>
                <TextButton onClick={() => promptLogin()}>Log in</TextButton>{' '}
                for checkout.
              </Text>
            </div>
          )}
          <Divider />
          <Text typography={TextTypography.largeTitle}>
            2. {t('checkout.title')}
          </Text>

          {renderPaymentWidget && (
            <PaymentWidgetContainer
              hideTitle={true}
              onApiReady={() => {}}
              onWidgetLoaded={() => {}}
            />
          )}
        </div>
      )}
      <div className={classes.checkoutLayout}>
        <PaymentCheckoutContainer
          onCouponApplied={(_coupon) => {
            setCoupon(_coupon);
          }}
        />
      </div>
    </section>
  );
};
