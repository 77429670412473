import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';

export function getInstance(flowAPI: ControllerFlowAPI) {
  const {
    controllerConfig: { wixCodeApi },
    environment: { appDefinitionId },
  } = flowAPI;
  return (
    wixCodeApi.location?.query?.instance ||
    wixCodeApi.site.getAppToken(appDefinitionId)
  );
}
/*
  Set an instance from flowAPI or Query.
  */
export const syncInstance = (flowAPI: ControllerFlowAPI) => {
  try {
    const instance = getInstance(flowAPI);

    if (instance /* && !flowAPI.environment.isEditor */) {
      flowAPI?.controllerConfig?.setProps?.({
        instance,
      });
    } else {
      handleError({
        error: new Error("Can't get instance"),
        context: 'instance',
      });
    }
  } catch (err) {
    console.error('YYY', err);
    handleError({
      error: new Error("Can't get instance"),
      context: 'instance',
    });
  }
};
