import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../../../services/request';
import {
  ListChallengesResponse,
  State,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { listChallenges } from '@wix/ambassador-challenges-v1-challenge/http';

const LIMIT = 100;

export const requestChallenges = async ({
  flowAPI,
  memberId,
  offset = 0,
  options = {},
}: {
  flowAPI: ControllerFlowAPI;
  memberId: string;
  offset: number;
  options?: any;
}): Promise<ListChallengesResponse> => {
  const params: any = {
    memberId,
    paging: {
      offset,
      limit: LIMIT,
    },
    states: [State.PUBLISHED, State.FINISHED],
    memberRoles: options.memberRoles || [],
  };

  if (options.sortingCriterion) {
    params.sortingCriterion = options.sortingCriterion;
  }

  if (options.categoryIds) {
    params.categoryIds = options.categoryIds;
  }

  return (
    await request({
      flowAPI,
      options: listChallenges(params),
      errorOptions: { errorCodesMap: { applicationError: {} } },
    })
  )?.data;
};

export const requestAllChallenges = async function ({
  flowAPI,
  memberId,
  options = {},
}: {
  flowAPI: ControllerFlowAPI;
  memberId: string;
  options?: any;
}): Promise<ListChallengesResponse> {
  const result = {
    totalCount: 0,
    memberChallenges: [],
  };

  const initial = await requestChallenges({
    flowAPI,
    memberId,
    offset: 0,
    options,
  });

  result.totalCount = initial.totalCount;
  result.memberChallenges = initial.memberChallenges;

  if (initial?.memberChallenges?.length === LIMIT) {
    const promises: Promise<ListChallengesResponse>[] = [];

    for (
      let requestedCont = LIMIT;
      requestedCont < initial.totalCount;
      requestedCont += LIMIT
    ) {
      promises.push(
        requestChallenges({
          flowAPI,
          memberId,
          offset: requestedCont,
          options,
        }),
      );
    }

    const nextChallenges = await Promise.all(promises);

    result.memberChallenges = [
      ...initial.memberChallenges,
      ...nextChallenges
        .map((item) => item.memberChallenges)
        .reduce((prev, current) => {
          return [...prev, ...current];
        }, []),
    ];
  }

  return result;
};
